import React from "react"
import SEO from "../components/SEO"

import Layout from "../components/layout"

const EventsPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div
        style={{
          minHeight: 500,
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
        }}
        className="text-blue-700 font-boldr"
      >
        Hello from the Winks Page
        <div className="bg-winks-logo bg-cover w-100 h-20"> </div>
      </div>
    </Layout>
  )
}

export default EventsPage
